/**
 * Overrides runtime globals as necessary. This must be imported before other dependencies in the
 * application.
 */
const globals = (window.__GLOBALS__ = window.__GLOBALS__ || {});

/**
 * SIDE-EFFECT: overrides the `deployUrl` used by lazy imports to correctly load the resources for
 * the configured CDN for the current env.
 */
/*if (globals.blobBaseUrl) {
  __webpack_public_path__ = globals.blobBaseUrl + __webpack_public_path__;
}*/

export {}; // NOTE: only needed for the ambient declaration.
declare global {
  /** This exists at build time {@link https://webpack.js.org/guides/public-path/#on-the-fly} */
  //var __webpack_public_path__: string;

  interface Window {
    __GLOBALS__?: {
      blobBaseUrl?: string;
      cacheBuster?: string;
      isRtl?: boolean;
      accountDTO?: any; // temp
      hideAppHeader?: boolean;
      isNativeAppContext?: boolean;
    };
  }
}
